import background1 from "../images/bg.png";
import background2 from "../images/bg_2.png";
import background3Layout from "../images/vectors/Rectangle_11.png";
import background3 from "../images/bg_3.png";
import background4 from "../images/bg_4.png";
import background5 from "../images/bg_5.png";
import background5Layout from "../images/vectors/Rectangle_12.png";

export const sectionsStyles = {
    section1: {
        backgroundImage: `url(${background1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
    },
    section2: {
        backgroundImage: `url(${background2})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        position: 'relative',
    },
    section2Before: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${background3Layout})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 1,
    },
    section3: {
        backgroundImage: `url(${background3})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        position: 'relative',
    },
    section3Before: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${background3Layout})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 1,
    },
    section4: {
        backgroundImage: `url(${background4})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        position: 'relative',
    },
    section4Before: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${background3Layout})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 1,
    },
    section5: {
        backgroundImage: `url(${background5})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        position: 'relative',
    },
    section5Before: {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundImage: `url(${background5Layout})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        opacity: 1,
    },
    section6: {
        backgroundColor: `#000000`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflowY: 'scroll',
        height: '103vh',
        position: 'relative',
    },
    // section7: {
    //     backgroundColor: `#000000`,
    //     backgroundRepeat: 'no-repeat',
    //     backgroundSize: 'cover',
    //     backgroundPosition: 'center',
    //     height: '30vh',
    //     position: 'relative',
    // },
};